import $ from 'jquery';
import * as LazyLoad from 'vanilla-lazyload';
import smoothscroll from 'smoothscroll-polyfill';

let currentGallery = [];

const production = window.location.href.indexOf('localhost') === -1;
const page = $('body');
const overlay = $('.overlay');
const btnPrevNext = $('.control.next, .control.prev');
const overlayImage = overlay.find('img');
const overlayCanvas = overlay.find('div.canvas');
const navigation = $('.navigation');
const hidden = 'disabled';
const locked = 'locked';

const showLoadedImage = (index: number, img: HTMLImageElement) => {
    const ratio = img.height / img.width;
    const calculatedHeight = Math.floor($(window).height() * .9);
    const calculatedWidth = calculatedHeight / ratio;

    overlayImage.css({
        height: calculatedHeight,
        width: calculatedWidth
    });

    overlayCanvas.css({
        height: calculatedHeight,
        width: calculatedWidth
    });

    let imgUrl = currentGallery[index];

    overlayImage.attr('src', imgUrl);
    setTimeout(() => {
        overlayImage.removeClass('loading');
    }, 25);
};
const hideOverlay = (event) => {
    event.preventDefault();

    overlayImage.attr('src', '#')
    page.removeClass(locked);
    overlay.addClass(hidden);
};
const showOverlay = (event) => {
    event.preventDefault();

    currentGallery.length = 0;

    $(event.currentTarget)
        .closest('.element')
        .find('img')
        .each((index, img) => {
            let imgUrl = img.getAttribute('data-highres') || img.getAttribute('data-src');

            currentGallery.push(imgUrl);
        });

    if (currentGallery.length === 1) {
        btnPrevNext.addClass('d-none');
    } else {
        btnPrevNext.removeClass('d-none');
    }

    const imgUrl = (
        event.currentTarget.getAttribute('data-highres') ||
        event.currentTarget.getAttribute('data-src')
    );

    fetchImage(currentGallery, currentGallery.indexOf(imgUrl), overlayImage, (index: number = 0, img: HTMLImageElement) => {
        showLoadedImage(index, img);
        overlayImage.attr('src', imgUrl);
    });

    overlayImage.addClass('loading');
    overlay.removeClass(hidden);
    page.addClass(locked);
};
const slideToPrev = (event) => {
    if (!!event) {
        event.preventDefault();
        event.stopPropagation();
    }
    if (!overlay.hasClass(hidden)) {
        const currentIndex = currentGallery.indexOf(overlayImage.attr('src'));
        let nextIndex = (currentIndex - 1 >= 0) ? currentIndex - 1 : currentGallery.length - 1;
        overlayImage.addClass('loading');
        fetchImage(currentGallery, nextIndex, overlayImage, showLoadedImage);
    }
};

const slideToNext = (event) => {
    if (!!event) {
        event.preventDefault();
        event.stopPropagation();
    }

    if (currentGallery.length === 1) {
        hideOverlay(event);
    }

    if (!overlay.hasClass(hidden)) {
        const currentIndex = currentGallery.indexOf(overlayImage.attr('src'));
        let nextIndex = (currentIndex + 1 < currentGallery.length) ? currentIndex + 1 : 0;

        overlayImage.addClass('loading');
        fetchImage(currentGallery, nextIndex, overlayImage, showLoadedImage);
    }
};
const showNavigation = () => {
    navigation.removeClass(hidden);
    page.addClass(locked);
};
const hideNavigation = () => {
    navigation.addClass(hidden);
    page.removeClass(locked);
};

const fetchImage = (gallery: string[],
                    index: number = 0,
                    imageElementRef: any,
                    cb = (index: number = 0, img: HTMLImageElement) => {
                    }) => {

    const img = new Image();
    const initTime = new Date().getTime();

    img.onload = () => {
        let durationString = overlayImage.css('transitionDuration');
        let delay = parseFloat(durationString.match(new RegExp(/^[0-9.]+/))[0]) * 1000 || 1000;
        const timeElapsed = new Date().getTime() - initTime;


        if (timeElapsed > delay) {
            cb(index, img);
        } else {

            setTimeout(() => {
                cb(index, img);
            }, delay - timeElapsed)
        }
    };

    img.src = gallery[index];
};


// Initialization Code
if ('serviceWorker' in navigator && production) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('../../service-worker.js');
    });
}

smoothscroll.polyfill();

new LazyLoad
    .default({
        elements_selector: 'img[data-src]',
        use_native: true
    })
    .update();


// Event handling
$(document)
    .on('keyup', 'body', (event) => {
        switch (event.code) {
            case 'Escape':
                hideOverlay(event);
                hideNavigation();
                break;
            case'ArrowLeft':
                slideToPrev(event);
                break;
            case'ArrowRight':
                slideToNext(event);
                break;
            default:
                break;
        }
    })

    .on('click', 'div.element img', showOverlay)

    .on('click', 'div.overlay img', slideToNext)
    .on('click', '.control.next', slideToNext)
    .on('click', '.control.prev', slideToPrev)

    .on('click', '.nav', showNavigation)
    .on('click', '.navigation', hideNavigation)
    .on('click', '.navigation a', (event) => event.stopPropagation())

    .on('click', 'div.overlay', hideOverlay)
    .on('click', '.control.leave', hideOverlay)
